import AsyncLoadingSwitch from '@/components/form/asyncLoading/switch/loadingSwitch';
import GraphqlTable from '@/components/graphqlTable';
import { getRowProps } from '@/components/graphqlTable/getDeliveryStatusRowStyling';
import PageSection from '@/components/page/section';
import { mutateGraphQL } from '@/data';
import { InvoicesRead } from '@/data/commerce/invoice.graphql';
import { useGraphqlResult } from '@/data/query/graphqlProvider';
import useUserInfo from '@/providers/auth/useUserInfo';
import { HouseAccount, MutationHouseAccountWriteArgs, Order, QueryInvoicesReadArgs } from '@/types/schema';
import { gql } from '@apollo/client';
import { Stack, Typography, useTheme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MobileRowPanel from '../../../commerce/components/mobileRow';
import InvoicesMultiActions from '../../../commerce/invoices/actions/multiActions';
import _useInvoiceActions from '../../../commerce/invoices/actions/tableActions';
import RowPanel from '../../../commerce/invoices/rowPanel';
import { columns } from '../../../commerce/invoices/table';
import { getFilteredDates } from './accountInvoices';

export default function HouseAccountInvoices( {
	month = '',
	allMonths,
	startOfWeek = null,
	endOfWeek = null,
	allWeeks,
	timezone,
}: {
	month?: string,
	allMonths?: boolean,
	startOfWeek?: Date,
	endOfWeek?: Date,
	allWeeks?: boolean,
	timezone: string
} ) {
	
	const houseAccount = useGraphqlResult<HouseAccount>();
	const theme = useTheme();
	const { staff } = useUserInfo();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { startDate, endDate } = getFilteredDates( month, startOfWeek, endOfWeek, timezone );
	
	const dark = theme.palette.mode === 'dark';
	
	const generateText = ( month, allMonths, startOfWeek, endOfWeek ) => {
		if ( month || allMonths ) {
			return allMonths ? '' : `in ${format( new Date( month ), 'MMMM' )}`;
		} else {
			return !allWeeks
				? `from ${format( startOfWeek, 'EEE, MMM d' )} - ${format( endOfWeek, 'EEE, MMM d' )}`
				: '';
		}
		
	};
	
	return (
		<PageSection
			primary={`All Orders ${generateText( month, allMonths, startDate, endDate )}`}
			actions={(
				<Stack direction='row' spacing={1} justifyContent='right' alignItems='center'>
					<Typography>{t( 'management:include-paid' )} </Typography>
					<AsyncLoadingSwitch
						checked={houseAccount.metadata?.includePaidInStatement ?? false}
						onChange={async ( e, checked: boolean ) => {
							await mutateGraphQL<MutationHouseAccountWriteArgs>( {
								mutation : gql`mutation HouseAccountWrite_d1ec($id: String,$method: String,  $input: HouseAccountValidator,$remove: Boolean) {
													houseAccountWrite(id: $id,   method: $method,input: $input, remove: $remove) { id}}`,
								variables: {
									id   : houseAccount.id,
									input: { name: houseAccount.name, metadata: { includePaidInStatement: checked } },
								},
							} );
							checked
								? enqueueSnackbar( 'Paid invoices will be included in the statement', { variant: 'info' } )
								: enqueueSnackbar( 'Paid invoices will not be included in the statement', { variant: 'info' } );
							await queryClient.invalidateQueries( [ 'houseAccount' ] );
						}}
					/>
				</Stack>
			)}>
			
			<GraphqlTable<Order, QueryInvoicesReadArgs>
				returnNullOnEmpty
				showFooter
				searchable
				disableUrlSync
				queryKey='invoice'
				query={InvoicesRead}
				subscription={{ INVOICE: staff.company.id }}
				columns={columns( 'houseAccount' )}
				rowProps={( row ) => getRowProps( row, dark )}
				variables={{
					options: {
						filter: {
							houseAccount: houseAccount.id,
							type        : 'INVOICE',
							metadata    : { templateName: null },
							status      : {
								$in: [ 'ACCOUNT',
								       houseAccount.metadata?.includePaidInStatement ? 'PAID' : undefined,
								       'PARTIALLY_PAID' ].filter( Boolean ),
							},
							$and        : [ {
								$or: allMonths || allWeeks ? undefined : [
									{ serviceDate: { $gte: startDate, $lte: endDate } },
									{
										$and: [
											{ serviceDate: { $eq: null } },
											{ createdAt: { $gte: startDate, $lte: endDate } },
										],
									},
								],
							} ],
						},
					},
				}}
				hiddenTableColumns='houseAccountInvoices'
				initialState={{
					hiddenColumns: staff.metadata?.houseAccountInvoices ? staff.metadata?.houseAccountInvoices : [
						'id',
						'Clover#',
						'Contact',
						'taxTotal',
						'updatedAt',
						'client.id',
						'metadata.signature',
						'houseAccount.id',
						'houseAccount',
						'metadata.sentDates',
						'po',
						'type',
						'dueDate',
					],
				}}
				expandedComponent={( row ) => <RowPanel row={row}/>}
				useActions={( invoice ) => _useInvoiceActions( invoice, undefined, true )}
				renderMultiActions={( invoices, clearRows, selectedRows ) => (
					<InvoicesMultiActions
						invoices={invoices}
						clearRows={clearRows}
						selectedInvoices={selectedRows}
						allMonths={allMonths}
						month={month}
						startOfWeek={startOfWeek}
						endOfWeek={endOfWeek}
						allWeeks={allWeeks}
					/>
				)}
				mobileRenderRow={( invoice ) => <MobileRowPanel data={invoice as any}/>}
			/>
		
		</PageSection>
	);
}
